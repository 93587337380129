import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
  signal,
  WritableSignal,
} from '@angular/core';
import { BadgePurpose, BadgeStatus } from './badge.models';
import { BadgeService } from './badge.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'sfa-badge',
  standalone: true,
  imports: [],
  templateUrl: './badge.component.html',
  styleUrl: './badge.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * A badge can be used anywhere like this:
 * <some-component>
 *   <sfa-badge [badgePurpose]="BadgePurpose.EMAIL_NOT_VERIFIED" />
 * </some-component>
 * But the preferred way is to use it with an icon like this:
 * <sfa-svg-icon
 *       src="person"
 *       svgClass="big"
 *       [badgePurpose]="BadgePurpose.EMAIL_NOT_VERIFIED"
 *     >
 * </sfa-svg-icon>
 */
export class BadgeComponent implements OnInit {
  protected readonly BadgeStatus = BadgeStatus;

  // TBD at some point, this should probably be an array
  @Input() badgePurposes!: BadgePurpose[];
  @Input() showBadgeStatusWithoutPurpose!: BadgeStatus;

  status$: WritableSignal<BadgeStatus> = signal(BadgeStatus.INACTIVE);

  private badgeService = inject(BadgeService);
  private destroy$ = inject(DestroyRef);

  ngOnInit(): void {
    if (this.badgePurposes) {
      this.badgeService
        .getPrioritizedStatus(this.badgePurposes)
        .pipe(takeUntilDestroyed(this.destroy$))
        .subscribe((status) => this.status$.set(status));
    }

    if (
      this.showBadgeStatusWithoutPurpose &&
      this.showBadgeStatusWithoutPurpose !== BadgeStatus.INACTIVE
    ) {
      this.status$.set(this.showBadgeStatusWithoutPurpose);
    }
  }
}
