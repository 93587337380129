import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';
import {
  BadgePurpose,
  BadgeStatus,
} from '../../../projects/app/src/app/shared/components/badge/badge.models';
import { BadgeComponent } from '../../../projects/app/src/app/shared/components/badge/badge.component';

@Component({
  selector: 'sfu-svg-icon',
  standalone: true,
  imports: [NgClass, BadgeComponent],
  templateUrl: './svg-icon.component.html',
  styleUrl: './svg-icon.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgIconComponent {
  @Input() src: string | undefined; // -> Must be a material icon src (https://fonts.google.com/icons?selected=Material+Symbols+Rounded:check:FILL@1;wght@400;GRAD@0;opsz@24&icon.style=Rounded)
  @Input() svgClass: string | undefined;
  @Input() badgePurposes!: BadgePurpose[];
  @Input() showBadgeStatusWithoutPurpose!: BadgeStatus; // -> use badgePurposes if possible and not showBadgeStatusWithoutPurpose
}
